import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import { Link } from 'gatsby'
import { MdKeyboardArrowRight } from 'react-icons/md'

const meta = {
  title: 'Page not found',
  description: ''
}

const NotFountPg = () => (
  <DefaultLayout {...meta} className="container padding">
    <header className="richtext padding-y text-center">
      <h1 className="h1">
        <strong className="cps-blue">Oops!</strong> Page not found
      </h1>
      <p>Apologies, but we were unable to find what you were looking for...</p>
      <p>Go back <Link to="/" className="link with-icon">home <MdKeyboardArrowRight /></Link></p>
    </header>
  </DefaultLayout>
)

export default NotFountPg
